/* src/components/InfoCard.module.css */
.infoCard {
  background-color: rgb(15, 15, 45);
  border-radius: 10px;
  padding: 20px;
  width: 318px; /* Adjust this value to better match the title's width + 10% */
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px; /* Add some space below the card */
}

.infoCard h2 {
  margin-bottom: 20px;
  text-align: center; /* Center the heading */
}

.infoCard p {
  margin-bottom: 20px;
  font-size: 1.1em; /* Increase the font size */
  text-align: justify; /* Full justify the text */
}
