.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: transparent; /* Make the header background transparent */
  color: white;
  position: fixed; /* Make the header fixed */

  width: 100%;
  top: 0;  
  z-index: 1000; /* Ensure the header is above other elements */
}

.logo {
  display: flex;
  align-items: center;
}

.logoImg {
  height: 45px;
  width: auto; /* Maintain aspect ratio */
}

.nav {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Center the navigation links */
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.nav button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.authButtons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 30px; /* Ensure enough space on the right */
}

.authButtons button {
  background-color: rgb(0, 106, 245);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  color: white;
}

/* Hover effects */
.nav button:hover {
  text-decoration: underline;
}

.authButtons button:hover {
  background-color: rgb(0, 90, 220); /* Slightly darker shade */
}