/* src/components/AppCard.module.css */
.appCard {
  background: linear-gradient(to bottom, rgb(45, 46, 92) 50%, rgb(28, 26, 58) 50%); /* Updated gradient background */
  border-radius: 10px;
  width: 200px; /* Fixed width */
  height: 300px; /* Fixed height */
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.appCardImageContainer {
  background-color: rgb(45, 46, 92);
  border-radius: 10px 10px 0 0;
  padding: 20px;
}

.appCardImage {
  width: 100%;
  height: auto;
}

.appCardContent {
  background-color: rgb(28, 26, 58);
  border-radius: 0 0 10px 10px;
  padding: 20px;
  text-align: left; /* Align text to the left */
}

.appCard h3 {
  margin: 0 0 10px 0;
}

.appCard p {
  font-size: 0.9em;
  line-height: 1.4;
  margin: 0;
}
