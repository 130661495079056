/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.landingPage {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgb(0,0,0);
  background: linear-gradient(179deg, rgba(0,0,0,1) 34%, rgba(51,29,96,1) 60%, rgba(186,187,188,1) 99%);
}
