/* src/components/ContactSection.module.css */
.contactSection {
  padding: 50px;
  background-color: rgb(40, 40, 80); /* Background color */
  color: white;
  text-align: center;
  min-height: 100vh; /* Ensure the section fills the viewport height */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

.contactSection h2 {
  margin-bottom: 20px;
}

.contactSection p {
  max-width: 600px; /* Limit the width of the text to match the input fields */
  margin: 0 auto 20px; /* Center the text and add margin below */
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%; /* Ensure the form takes up full width */
  max-width: 600px; /* Ensure the form does not exceed the max width */
}

.contactForm input,
.contactForm textarea {
  width: 100%; /* Ensure the fields take up full width */
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.contactForm textarea {
  height: 150px; /* Default height for the message textarea */
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto; /* Add vertical scrollbar as needed */
}

.contactForm button {
  padding: 10px 20px;
  background-color: rgb(0, 106, 245);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contactForm button:hover {
  background-color: rgb(0, 90, 220);
}
