.applicationsSection {
  padding: 70px 50px 50px 50px; /* Adjusted top padding to 70px */
  background-color: rgb(21, 22, 69); /* Updated background color */
  color: white;
  text-align: center;
  min-height: 100vh; /* Ensure the section fills the viewport height */
}

.appCardsContainer {
  display: grid;
  grid-template-columns: repeat(5, 200px); /* Five columns with fixed width */
  column-gap: 60px; /* Horizontal gap between cards */
  row-gap: 60px; /* Vertical gap between cards */
  justify-content: center; /* Center the grid */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}