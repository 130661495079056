/* src/components/PricingSection.module.css */
.pricingSection {
  padding: 50px;
  padding-top: 100px; /* Add padding to the top to move content down */
  background-color: rgb(15, 15, 45); /* Background color */
  color: white;
  min-height: 100vh; /* Ensure the section fills the viewport height */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center content vertically */
}

.textContainer {
  max-width: 1200px; /* Ensure the text container does not stretch too wide */
  text-align: left; /* Left justify the text */
}

.pricingSection h2,
.pricingSection p {
  margin-bottom: 20px;
}

.pricingSection h3 {
  margin-bottom: 40px;
  text-align: left; /* Left justify the text */
}

.pricingCardsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricingCardsContainer {
  display: flex;
  justify-content: center;
  gap: 60px; /* Horizontal and vertical gap between cards */
  max-width: 100%; /* Ensure the cards container does not stretch too wide */
  flex-wrap: nowrap; /* Prevent wrapping to a new row */
  position: relative; /* Add relative positioning */
}

.textAlignContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.textAlignContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto; /* Align to the left edge of the first card */
}

.textAlignContent .textContainer {
  display: inline-block;
  width: auto; /* Make the width dynamic based on content */
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textAndCards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px; /* Maintain space between text and first card */
}

@media (min-width: 768px) {
  .textAndCards {
    flex-direction: row;
    align-items: flex-start;
  }
}

/* Positioning the star and text */
.starTextContainer {
  position: absolute;
  width: auto; /* Allow the container to expand to fit the text */
  top: -50px; /* Move the star and text up more */
  left: 10px; /* Adjust the left position */
  display: flex; /* Align items in a row */
  align-items: center; /* Align items vertically */
}

.globalStar {
  font-size: 1.5em; /* Set the font size for the star */
  color: yellow; /* Set the color to yellow */
  margin-right: 5px; /* Add space between the star and the text */
}

.starText {
  font-size: 1.4em; /* Increase the font size for the text */
  font-weight: 600; /* Slightly bold the text */
  color: white; /* Set the text color */
  white-space: nowrap; /* Prevent text from wrapping */
}
