.heroSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  background-image: url('../../assets/landing-page_background.png'); /* Adjusted path */
  background-size: cover;
  background-position: center;
  text-align: left; /* Changed from center to left */
  min-height: 100vh; /* Use min-height instead of height */
  position: relative;
  box-sizing: border-box; /* Ensure padding is included in the height calculation */
}
.introVideo {
  position: absolute; /* Change this to absolute for easier positioning */
  bottom: 40px; /* Move the video down by 20px from the bottom */
  right: 75px; /* Move the video right by 20px from the right */
  width: 100%; /* Adjust width as needed */
  max-width: 600px; /* Set a max-width for the video */
  height: auto; /* Maintain aspect ratio */
  z-index: 1; /* Ensure video is above the background */
}

.heroContent {
  max-width: 800px;
  color: white; /* Ensure text is readable on the background */
  position: relative;
  text-align: left; /* Changed from center to left */
  z-index: 2; /* Ensure content is above the video */
}

.heroContent h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 400; /* Adjust font weight to be less bold */
}

.heroContent p {
  font-size: 1.5em;
  margin-bottom: 40px;
  line-height: 1.5; /* Add line height for better readability */
  max-width: 800px; /* Adjust max-width to ensure the paragraph doesn't span too wide */
  margin-left: 0; /* Remove auto left margin */
  margin-right: 0; /* Remove auto right margin */
  text-align: left; /* Ensure text alignment is left */
}

.heroButtons {
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
  align-items: flex-start; /* Align buttons to the left */
  gap: 10px; /* Adjust the gap as needed */
}

.getStarted {
  background-color: rgb(0, 106, 245);
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none; /* Add this to remove the underline */
  font-size: 1.2em; /* Ensure the button text is larger */
}

.getStarted img {
  width: 20px; /* Adjust the arrow image size */
  height: 20px; /* Ensure the aspect ratio is maintained */
}

.purchaseNow {
  color: white; /* Change text color to white */
  text-decoration: underline; /* Add underline */
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2em; /* Ensure the button text is larger */
}

.purchaseNow img {
  width: 16px; /* Scale down the arrow image */
  height: 16px; /* Ensure the aspect ratio is maintained */
  filter: invert(1); /* Change the arrow color to white */
}