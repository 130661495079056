/* src/components/AboutUsSection.module.css */
.aboutUsSection {
  padding: 50px;
  background-color: rgb(30, 30, 70); /* Background color */
  color: white;
  text-align: center;
  min-height: 100vh; /* Ensure the section fills the viewport height */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Arrange children in a column */
  justify-content: center; /* Center content vertically */
}

.aboutUsSection h1 {
  margin-bottom: 40px;
  font-size: 2.5em;
}

.aboutUsSection h2 {
  margin-bottom: 20px;
  font-size: 2em;
}

.aboutUsSection p {
  max-width: 800px; /* Limit the width of the text */
  margin: 0 auto 40px; /* Center the text and add bottom margin */
  font-size: 1.2em;
}
