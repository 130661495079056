/* src/components/PricingCard.module.css */
.pricingCard {
  background-color: rgb(28, 26, 58);
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* Add relative positioning to the card */
}

.pricingCard h4 {
  margin-bottom: 10px;
}

.pricing {
  margin-bottom: 20px;
}

.price {
  font-size: 2em;
  display: block;
}

.billing {
  font-size: 0.9em;
  color: #ccc;
}

.pricingButton {
  background-color: #ffcc00;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  color: black;
  margin-bottom: 20px;
}

.units {
  margin-bottom: 20px;
}

.features {
  list-style: none;
  padding: 0;
  text-align: left;
}

.features li {
  margin-bottom: 10px;
}

.star {
  color: yellow; /* Set the star color to yellow */
  position: absolute; /* Position the star absolutely */
  top: 10px; /* Adjust the top position */
  left: 10px; /* Adjust the left position */
  font-size: 1.5em; /* Adjust the font size */
}
